@import 'stream-chat-react/dist/css/v2/index.css';

.str-chat {
  --str-chat__spacing-0_5: 4px;
  --str-chat__spacing-8: 8px;
  --str-chat__spacing-10: 32px;
  --str-chat__message-bubble-border-radius: 8px;
  --str-chat__message-textarea-border-radius: 22.5px;
  --str-chat__message-textarea-background-color: #ffffff; /* shared.white */
  --str-chat__secondary-surface-color: #ffffff; /* shared.white */
  --str-chat__background-color: #fdfcf4; /* juniper.cream */
  --str-chat__secondary-background-color: #fdfcf4; /* juniper.cream */
  --str-chat__message-bubble-color: #1a1a1a; /* shared.neutral900 */
  --str-chat__own-message-bubble-color: #f2ffca; /* juniper.lime100 */
  --str-chat__primary-surface-color: #133f26; /* juniper.primary500 */
  --str-chat__message-bubble-border-block-start: 1px solid #d0d8bc; /* juniper.primary200 */
  --str-chat__message-bubble-border-block-end: 1px solid #d0d8bc; /* juniper.primary200 */
  --str-chat__message-bubble-border-inline-start: 1px solid #d0d8bc; /* juniper.primary200 */
  --str-chat__message-bubble-border-inline-end: 1px solid #d0d8bc; /* juniper.primary200 */
  --str-chat__font-family: Atlas Grotesk, ui-sans-serif;
  --str-chat__quoted-message-bubble-background-color: #ffffff;
  --str-chat__body2-text: 400 0.875rem/150% var(--str-chat__font-family);
  --str-chat__message-input-tools-color: #133f26;
  --str-chat__message-send-color: #133f26;
  --str-chat__loading-indicator-color: #133f26; /* juniper.primary500 */
}

.str-chat__message--me .str-chat__message-bubble {
  border-block-start: none;
  border-block-end: none;
  border-inline-start: none;
  border-inline-end: none;
}

.str-chat__message-url-link {
  @apply underline cursor-pointer text-primary-500 !important;
  font-weight: 500 !important;
}

.str-chat__message
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text
  p:not(:first-of-type),
.str-chat__quoted-message-preview
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text
  p:not(:first-of-type) {
  margin: 12px 0 0;
}

.str-chat__message-attachment__voice-recording-widget__title,
.str-chat__attachment-preview-file-name,
.str-chat__attachment-list
  .str-chat__message-attachment__voice-recording-widget
  .str-chat__message-attachment__voice-recording-widget__right-section {
  display: none;
}

.str-chat-unread-message-separator-text {
  color: #000000 !important;
}
